export enum ConfiguratorInputOptionActionsTypes {
  READ = "@@CONFIGURATOR_INPUT_OPTION read configurator-input-option",
  READ_STATUS = "@@CONFIGURATOR_INPUT_OPTION read status configurator-input-option",
  PATCH_ONE = "@@CONFIGURATOR_INPUT_OPTION patch one configurator-input-option",
  PATCH_ONE_STATUS = "@@CONFIGURATOR_INPUT_OPTION patch one status configurator-input-option",
  DELETE_ONE = "@@CONFIGURATOR_INPUT_OPTION delete one configurator-input-option",
  DELETE_ONE_STATUS = "@@CONFIGURATOR_INPUT_OPTION delete one status configurator-input-option",
  CREATE_ONE = "@@CONFIGURATOR_INPUT_OPTION create one configurator-input-option",
  CREATE_ONE_STATUS = "@@CONFIGURATOR_INPUT_OPTION create one status configurator-input-option",
  DELETE_MULTI = "@@CONFIGURATOR_INPUT_OPTION delete multi configurator-input-option",
  UPDATE_MULTI = "@@CONFIGURATOR_INPUT_OPTION update multi configurator-input-option",
  DELETE_MULTI_STATUS = "@@CONFIGURATOR_INPUT_OPTION delete multi status configurator-input-option",
  UPDATE_MULTI_STATUS = "@@CONFIGURATOR_INPUT_OPTION update multi status configurator-input-option"
}

import { ObjectSchema } from "yup";
import { DynamicJsonTranslator } from "../../utils/function/jsonTranslator";
import { EntityType } from "../events/type";
import {
  CustomFieldNode,
  CustomFieldNodesType
} from "./customFieldNodes/types";
import { MaterialTableColumn } from "../../components/common/MyMaterialTable2RTK";

export enum MetaEntityHipe {
  Task = EntityType.Tasks,
  Project = EntityType.Projects,
  Company = EntityType.Companies,
  User = EntityType.User,
  Brief = EntityType.Briefs,
  Submission = EntityType.Submissions,
  File = EntityType.File,
  Contact = "CONTACTS"
}

export const metaEntityHipeLabel = {
  [MetaEntityHipe.Task]: MetaEntityHipe.Task,
  [MetaEntityHipe.Project]: MetaEntityHipe.Project,
  [MetaEntityHipe.Company]: MetaEntityHipe.Company,
  [MetaEntityHipe.User]: MetaEntityHipe.User,
  [MetaEntityHipe.Brief]: MetaEntityHipe.Brief,
  [MetaEntityHipe.Submission]: MetaEntityHipe.Submission,
  [MetaEntityHipe.File]: MetaEntityHipe.File,
  [MetaEntityHipe.Contact]: MetaEntityHipe.Contact
};

export interface MetaEntity {
  id: string;
  name: MetaEntityHipe;
  label: DynamicJsonTranslator;
  enabled: boolean;
  custom?: boolean;
  customFields?: CustomFieldNode[];
  createdAt: string;
  updatedAt: string;
}

export interface MetaEntityLogics {
  hasPrecondition?: boolean;
  require?: boolean;
  schema?: ObjectSchema<any>; //MixedSchema;
  min?: number;
  max?: number;
  type?: CustomFieldNodesType;
}

export type CustomFieldColumn<T> = Omit<MaterialTableColumn<T>, "render"> & {
  customFieldNode: CustomFieldNode;
};

export interface MetaEntityTransform extends MetaEntity {
  schema: ObjectSchema<any>;
  initialValues: Record<string, any>;
  columns: Array<CustomFieldColumn<any>>;
  /** precondition formulas different than "true" */
  preconditions: Record<string, string>;
}

export interface MetaEntityBreadCrumProps {
  parentNode?: Array<{
    label: DynamicJsonTranslator;
    id: string;
    type: CustomFieldNodesType;
  }>;
  metaEntity?: { label: MetaEntityHipe; id: string };
}

export interface CustomFieldNodeWithRelations {
  noderef: CustomFieldNode;
  breadcrumb: MetaEntityBreadCrumProps;
  parentFormNode?: CustomFieldNode;
}

export enum MetaEntityTabsValues {
  GENERAL = "general",
  ELEMENTS = "custom_fields",
  GROUP_ELEMENTS = "groups_elements",
  ARRAY_ELEMENTS = "array_elements",
  LOGIC = "logic",
  ADVANCED_CONDITIONS = "advanced_conditions",
  DISPLAY_OPTIONS = "display_options",
  VALUES = "values"
}

// Mock data for storybook

export const mockMetaEntities: MetaEntity[] = [
  {
    id: "1",
    name: MetaEntityHipe.Company,
    enabled: true,
    custom: false,
    label: { "fr-FR": "Entreprises", "en-US": "Companies" },
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000",
    customFields: [
      {
        id: "1",
        type: CustomFieldNodesType.TEXT,
        metaEntityId: "1",
        inputName: "Text",
        enabled: true,
        position: 1,
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "2",
        type: CustomFieldNodesType.TEXT_AREA,
        metaEntityId: "1",
        enabled: true,
        position: 2,
        inputName: "Text Area",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "3",
        type: CustomFieldNodesType.NUMBER,
        metaEntityId: "1",
        enabled: true,
        position: 3,
        inputName: "Number",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      }
    ]
  },
  {
    id: "2",
    name: MetaEntityHipe.Company,
    enabled: true,
    custom: false,
    label: { "fr-FR": "Entreprises", "en-US": "Companies" },
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000",
    customFields: [
      {
        id: "1",
        type: CustomFieldNodesType.TEXT,
        metaEntityId: "1",
        inputName: "Text",
        enabled: true,
        position: 1,
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "2",
        type: CustomFieldNodesType.TEXT_AREA,
        metaEntityId: "1",
        enabled: true,
        position: 2,
        inputName: "Text Area",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "3",
        type: CustomFieldNodesType.NUMBER,
        metaEntityId: "1",
        enabled: true,
        position: 3,
        inputName: "Number",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      }
    ]
  },
  {
    id: "3",
    name: MetaEntityHipe.Company,
    label: { "fr-FR": "Entreprises", "en-US": "Companies" },
    enabled: true,
    custom: false,
    createdAt: "2021-01-01T00:00:00.000",
    updatedAt: "2021-01-01T00:00:00.000",
    customFields: [
      {
        id: "1",
        type: CustomFieldNodesType.TEXT,
        enabled: true,
        inputName: "Text",
        metaEntityId: "1",
        position: 1,
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "2",
        type: CustomFieldNodesType.TEXT_AREA,
        metaEntityId: "1",
        enabled: true,
        position: 2,
        inputName: "Text Area",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      },
      {
        id: "3",
        type: CustomFieldNodesType.NUMBER,
        metaEntityId: "1",
        enabled: true,
        position: 3,
        inputName: "Number",
        createdAt: "2021-01-01T00:00:00.000",
        updatedAt: "2021-01-01T00:00:00.000"
      }
    ]
  }
];

export const mockOneMetaEntities: MetaEntity = {
  id: "1",
  name: MetaEntityHipe.Company,
  enabled: true,
  custom: false,
  label: { "fr-FR": "Entreprises", "en-US": "Companies" },
  createdAt: "2021-01-01T00:00:00.000",
  updatedAt: "2021-01-01T00:00:00.000",
  customFields: [
    {
      id: "1",
      type: CustomFieldNodesType.TEXT,
      metaEntityId: "1",
      /* metaEntities: mockMetaEntities, */
      enabled: true,
      logics: [],
      design: {
        label: { "fr-FR": "Text", "en-US": "Text" }
      },
      inputName: "Text",
      position: 1,
      createdAt: "2021-01-01T00:00:00.000",
      updatedAt: "2021-01-01T00:00:00.000"
    },
    {
      id: "2",
      type: CustomFieldNodesType.TEXT_AREA,
      metaEntityId: "1",
      /*  metaEntities: mockMetaEntities, */
      enabled: true,
      design: {
        label: { "fr-FR": "Toto", "en-US": "Toto" }
      },
      logics: [],
      position: 2,
      inputName: "Text Area",
      createdAt: "2021-01-01T00:00:00.000",
      updatedAt: "2021-01-01T00:00:00.000"
    },
    {
      id: "3",
      type: CustomFieldNodesType.NUMBER,
      enabled: true,
      design: {
        label: { "fr-FR": "Titi", "en-US": "Titi" }
      },
      logics: [],
      metaEntityId: "1",
      /*  metaEntities: mockMetaEntities, */
      position: 3,
      inputName: "Number",
      createdAt: "2021-01-01T00:00:00.000",
      updatedAt: "2021-01-01T00:00:00.000"
    },
    {
      id: "4",
      enabled: true,
      type: CustomFieldNodesType.SELECT,
      design: {
        label: { "fr-FR": "Tata", "en-US": "Tata" }
      },
      metaEntityId: "1",
      /*   metaEntities: mockMetaEntities, */
      position: 7,
      inputName: "Select",
      options: [
        {
          id: "1",
          value: "1",
          label: { fr: "Option 1", en: "Option 1" },
          createdAt: "2021-01-01T00:00:00.000",
          position: 1
        },
        {
          id: "2",
          value: "2",
          label: { fr: "Option 2", en: "Option 2" },
          createdAt: "2021-01-01T00:00:00.000",
          position: 2
        },
        {
          id: "3",
          value: "3",
          label: { fr: "Option 3", en: "Option 3" },
          createdAt: "2021-01-01T00:00:00.000",
          position: 3
        }
      ],
      logics: [],
      createdAt: "2021-01-01T00:00:00.000",
      updatedAt: "2021-01-01T00:00:00.000"
    }
  ]
};

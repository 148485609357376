import { get } from "lodash";
import { LogicAction, Logics } from "../forms/type";
import {
  CustomFieldNode,
  CustomFieldNodesType
} from "./customFieldNodes/types";
import {
  MetaEntity,
  MetaEntityHipe,
  MetaEntityLogics,
  MetaEntityTransform
} from "./types";
import {
  ArraySchema,
  BooleanSchema,
  MixedSchema,
  NumberSchema,
  StringSchema,
  array,
  boolean,
  date,
  mixed,
  number,
  object,
  string
} from "yup";
import i18n from "../../utils/i18n";
import { CustomFieldValue } from "./customFieldValues/types";
import i18next from "i18next";
import { store } from "../../reducers/store";
import { PreconditionsState } from "../../components/common/form/MyCustomFieldInputs";

type ValueOf<T> = T[keyof T];

/**
 * Creates a schema based on field type and validation rules
 */
const createSchemaForType = (
  type: CustomFieldNodesType | undefined,
  options: {
    require?: boolean;
    min?: number;
    max?: number;
    applyValidation?: boolean;
  }
): MixedSchema => {
  const { require, min, max, applyValidation = true } = options;
  let schema: MixedSchema = mixed();

  switch (type) {
    case CustomFieldNodesType.TEXT:
    case CustomFieldNodesType.SELECT:
    case CustomFieldNodesType.RADIO:
    case CustomFieldNodesType.TEXT_AREA:
      schema = string().nullable();

      if (applyValidation) {
        if (require) {
          schema = schema.required(
            i18n.t("yup:mixed.required")
          ) as StringSchema;
        }
        if (min) {
          schema = (schema as StringSchema).min(min);
        }
        if (max) {
          schema = (schema as StringSchema).max(max);
        }
      }
      break;
    case CustomFieldNodesType.DATE:
      schema = date();

      if (applyValidation && require) {
        schema = schema.required().typeError(i18n.t("yup:mixed.required"));
      } else {
        schema = schema.nullable();
      }
      break;
    case CustomFieldNodesType.NUMBER:
      schema = number().nullable();

      if (applyValidation) {
        if (require) {
          schema = schema
            .typeError(i18n.t("yup:mixed.required"))
            .required() as StringSchema;
        }
        if (min || typeof min === "number") {
          schema = (schema as NumberSchema).min(
            min,
            i18n.t("yup:number.min", { min })
          );
        }
        if (max) {
          schema = (schema as NumberSchema).max(
            max,
            i18n.t("yup:number.max", { max })
          );
        }
      }
      break;
    case CustomFieldNodesType.CHECKBOX:
    case CustomFieldNodesType.SWITCH:
      schema = boolean();

      if (applyValidation && require) {
        schema = (schema as BooleanSchema)
          .required()
          .oneOf([true], i18n.t("yup:checkbox.checked"));
      }
      break;
    case CustomFieldNodesType.MULTI_SELECT:
      schema = array();

      if (applyValidation) {
        if (require) {
          schema = (schema as ArraySchema<string>)
            .notOneOf([undefined], i18n.t("yup:mixed.required"))
            .min(1, i18n.t("yup:mixed.required"));
        }
        if (min) {
          schema = (schema.required() as ArraySchema<string>).min(
            min,
            i18n.t("yup:array.min", { min })
          );
        }
        if (max) {
          schema = (schema.required() as ArraySchema<string>).max(
            max,
            i18n.t("yup:array.max", { max })
          );
        }
      }
      break;
    default:
      // Keep default schema
      break;
  }

  return schema;
};

/**
 * Creates a schema for a customField based on its logics
 * - take into account the precondition, require, min, max
 * @param key - The name of the customField
 * @param value - The logics of the customField
 * @returns A MixedSchema for the customField
 */
export const getNodeSchemaFromLogics = (
  key: string,
  value: MetaEntityLogics
): MixedSchema => {
  const { max, min, require, type, hasPrecondition } = value;

  // If there's no precondition, directly apply validation rules without conditional logic
  if (!hasPrecondition) {
    return createSchemaForType(type, { require, min, max });
  } else {
    // Only use conditional logic when there's a precondition
    // Create a function that checks if the precondition is met
    const isPreconditionMet = function (
      preconditions: PreconditionsState | undefined
    ) {
      // Check if the precondition for this field is true
      return preconditions?.[key] === true;
    };

    // Validate input based on precondition
    return mixed().when("$preconditions", {
      is: isPreconditionMet,
      then: () => {
        // When precondition is true, validate based on CustomFieldNodesType with all rules
        return createSchemaForType(type, { require, min, max });
      },
      otherwise: () => {
        // When precondition is false or undefined, return nullable schema without validation
        return createSchemaForType(type, { applyValidation: false });
      }
    });
  }
};

export const setNodeSchema = (
  values: Record<string, any>
): Record<string, MixedSchema> => {
  if (!values) return {};
  const schema = Object.entries(values).reduce(
    (acc: Record<string, MixedSchema>, [key, value]) => {
      acc[key] = getNodeSchemaFromLogics(key, value);
      return acc;
    },
    {}
  );
  return schema;
};

export const getDefaultValueCustomFieldNode = (node: CustomFieldNode) => {
  const required = node.logics?.find(
    (item) => item.action === LogicAction.REQUIRE
  )?.require;
  switch (node.type) {
    case CustomFieldNodesType.MULTI_SELECT:
    case CustomFieldNodesType.MULTI_CHECKBOX:
      return required ? [] : [null];
    case CustomFieldNodesType.CHECKBOX:
    case CustomFieldNodesType.SWITCH:
      return false;
    case CustomFieldNodesType.TEXT:
    case CustomFieldNodesType.TEXT_AREA:
    case CustomFieldNodesType.SELECT:
    case CustomFieldNodesType.RADIO:
    case CustomFieldNodesType.FILE:
      return "";
    case CustomFieldNodesType.NUMBER:
    case CustomFieldNodesType.PERCENTAGE:
    case CustomFieldNodesType.DATE:
      return null;
    default:
      return undefined;
  }
};

const getKeyLogic = <T extends ValueOf<Logics>>(
  action: LogicAction,
  value: CustomFieldNode,
  key: keyof Logics
): T | undefined | null => {
  const logic = value?.logics?.find(
    (item: Logics) => action === item.action
  ) as Logics;
  if (!logic) return undefined;
  return get(logic, key) as T;
};

export const getFormNodePath = (node: CustomFieldNode, parentPath?: string) => {
  const identifier = node.inputName || node.id;
  return parentPath ? `${parentPath}.${identifier}` : identifier;
};

const setLogicsTest = (
  customFieldNodes: CustomFieldNode[],
  parentPath?: string
): Record<string, MetaEntityLogics> => {
  let formArrayLogics: Record<string, MetaEntityLogics>;
  return Object.entries(customFieldNodes).reduce((formLogics, [__, node]) => {
    const formulaLogic = getKeyLogic<string>(
      LogicAction.PRECONDITION,
      node,
      "formula"
    );

    const requireLogic = getKeyLogic<boolean>(
      LogicAction.REQUIRE,
      node,
      "require"
    );

    const maxLogic = getKeyLogic<number>(LogicAction.MAX, node, "max");
    const minLogic = getKeyLogic<number>(LogicAction.MIN, node, "min");

    const currentPath = getFormNodePath(node, parentPath);

    formLogics[currentPath] = {
      hasPrecondition: formulaLogic && formulaLogic !== "true" ? true : false,
      require: typeof requireLogic === "boolean" ? requireLogic : false,
      min: typeof minLogic === "number" ? minLogic : undefined,
      max: typeof maxLogic === "number" ? maxLogic : undefined,
      type: node.type
    };

    if (node.children && (node.children?.length as number) > 0) {
      formArrayLogics = setLogicsTest(node.children, `${node.id}.0`);
    }

    return formArrayLogics ? { ...formLogics, ...formArrayLogics } : formLogics;
  }, {} as Record<string, MetaEntityLogics>);
};

const formatCustomFieldValue = (node: CustomFieldNode) => (
  customFields: CustomFieldValue[]
) => {
  if (!Array.isArray(customFields)) return undefined;

  const value = JSON.parse(
    customFields.find((cf) => cf.inputName === node.inputName)?.value || "null"
  );

  let neverHappen: never;
  switch (node.type) {
    case CustomFieldNodesType.DATE:
      return value
        ? new Date(value).toLocaleDateString(i18next.language, {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
          })
        : undefined;
    case CustomFieldNodesType.PERCENTAGE:
      return value ? value * 100 + " %" : undefined;
    case CustomFieldNodesType.CHECKBOX:
    case CustomFieldNodesType.SWITCH:
    case CustomFieldNodesType.NUMBER:
    case CustomFieldNodesType.TEXT:
    case CustomFieldNodesType.TEXT_AREA:
    case CustomFieldNodesType.SELECT:
    case CustomFieldNodesType.MULTI_SELECT:
    case CustomFieldNodesType.RADIO:
      return value;
    case CustomFieldNodesType.FILE:
    case CustomFieldNodesType.TITLE:
    case CustomFieldNodesType.CONTEXT:
    case CustomFieldNodesType.MULTI_CHECKBOX:
      console.warn("not managed", node.type);
      return value;
    default:
      neverHappen = node.type;
      console.warn("should not happen", node.type);
      return value;
  }
};

export const transformMetaEntity = (
  responseData: MetaEntity
): MetaEntityTransform => {
  const customFields = responseData.customFields;
  const user = store.getState()?.authentication?.user;
  const isGuest = !user?.roleIds?.length;

  if (customFields?.length === 0 || !customFields || isGuest)
    return {
      ...responseData,
      schema: object(),
      initialValues: {},
      columns: [],
      preconditions: {}
    };

  const createShemaLogics = setLogicsTest(customFields);

  const customFieldsSchema = object<any>({
    customFields: object().shape(setNodeSchema(createShemaLogics))
  });

  return {
    ...{
      ...responseData
    },
    schema: customFieldsSchema || [],
    initialValues: customFields.reduce((acc, node) => {
      const value = getDefaultValueCustomFieldNode(node);
      if (value !== undefined) {
        acc[getFormNodePath(node)] = value;
      }
      return acc;
    }, {} as Record<string, any>),
    columns: customFields
      .filter((node) => node.displayOptions?.showInTables)
      .map((node) => {
        return {
          title: node.inputName,
          field: `rawCustomFields`,
          type: [
            CustomFieldNodesType.NUMBER,
            CustomFieldNodesType.PERCENTAGE
          ].includes(node.type)
            ? "numeric"
            : undefined,
          format: formatCustomFieldValue(node),
          customFieldNode: node,
          disableSort: true
        };
      }),
    preconditions: customFields.reduce((acc, node) => {
      const precondition = node.logics?.find(
        (item) => item.action === LogicAction.PRECONDITION
      )?.formula;
      if (typeof precondition === "string" && precondition !== "true") {
        const inputRegex = /\$(entity.)?([a-zA-Z0-9]+)\b/g;
        const formulaEvaluable = precondition.replace(
          inputRegex,
          (match, isEntity, inputName) => {
            const inputPathEvaluable = [
              "values",
              isEntity ? undefined : "customFields",
              inputName
            ]
              .filter(Boolean)
              .join("?.");
            return inputPathEvaluable;
          }
        );
        acc[getFormNodePath(node)] = formulaEvaluable;
      }
      return acc;
    }, {} as Record<string, any>)
  };
};

export const transformMetaEntities = (
  responseData: Record<MetaEntityHipe, MetaEntity>
): Record<MetaEntityHipe, MetaEntityTransform> => {
  return Object.entries(responseData).reduce((acc, [key, value]) => {
    acc[key as MetaEntityHipe] = transformMetaEntity(value);
    return acc;
  }, {} as Record<MetaEntityHipe, MetaEntityTransform>);
};

import { Request } from "../../utils/request";
import {
  ConfiguratorInputOptionCreateDTO,
  ConfiguratorInputOptionPatchDTO,
  ConfiguratorInputOptionUpdateDTO,
  ConfiguratorInputOptionUpdateMultiDTO
} from "./entity";

export const read = () =>
  Request({ withToken: true }).get("/configurator-input-options");

export const readOne = (id: string) =>
  Request({ withToken: true }).get(`/configurator-input-options/${id}`);

export const createOne = (
  configuratorInputOption: ConfiguratorInputOptionCreateDTO
) =>
  Request({ withToken: true }).post(
    "/configurator-input-options",
    configuratorInputOption
  );

export const update = ({ id, ...dto }: ConfiguratorInputOptionUpdateDTO) =>
  Request({ withToken: true }).patch(`/configurator-input-options/${id}`, dto);

export const patch = ({ id, ...dto }: ConfiguratorInputOptionPatchDTO) =>
  Request({ withToken: true }).patch(`/configurator-input-options/${id}`, dto);

export const deleteOne = (id: string) =>
  Request({ withToken: true }).delete(`/configurator-input-options/${id}`);

export const deleteMulti = (ids: string[]) =>
  Request({ withToken: true }).delete(
    "/configurator-input-options/bulk-delete",
    {
      data: { ids: ids }
    }
  );

export const updateMulti = (dto: ConfiguratorInputOptionUpdateMultiDTO[]) =>
  Request({ withToken: true }).patch(
    "/configurator-input-options/bulk-update",
    dto
  );

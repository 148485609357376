import {
  Box,
  BoxProps,
  createStyles,
  Divider,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  TableSortLabel,
  Theme,
  Typography,
  Checkbox
} from "@material-ui/core";
import { grey, indigo } from "@material-ui/core/colors";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { TableCellProps } from "@material-ui/core/TableCell";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import EyeIcon from "@material-ui/icons/Visibility";
import UpdateIcon from "@material-ui/icons/Edit";
import clsx, { ClassValue } from "clsx";
import get from "lodash/get";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  AutoSizer,
  ColumnSizer,
  GridCellRenderer,
  Index,
  MultiGrid
} from "react-virtualized";
import { useDebouncedCallback } from "use-debounce";
import { FetchingStatus } from "../../../utils/reducers/fetchingStatus";
import { EmptyState, EmptyStateProps } from "../EmptyState";
import { MyButton, MyButtonProps } from "../MyButton";
import { MyFormikProps } from "../MyFormik";
import { MaterialTableActions } from "../MyMaterialTable2Virtualize/components/Actions";
import {
  MaterialTableFormDialog,
  MaterialTableFormDialogProps
} from "../MyMaterialTable2Virtualize/components/DialogForm";
import { MaterialTableSearchRTK } from "./components/SearchRTK";
import { ReactComponent as CopyToClipboardIcon } from "../../../assets/images/icons/copyToClipboard.svg";
import useCurrencyDisplay, {
  UseCurrencyDisplayParams
} from "../../../utils/hooks/useCurrencyDisplay";
import {
  CurrencyContext,
  CurrencyTable
} from "../../../services/currencies/currencies.type";
import { alpha } from "@material-ui/core/styles";

export const useStylesMaterialTable = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    header: {
      outline: "none"
    },
    cell: {
      color: "inherit",
      padding: "0px 12px 0px 12px",
      borderRight: "0.3px solid #e7e7e7"
    },
    cellDisabled: {
      background: theme.palette.grey[200]
    },
    cellHeader: {
      color: "inherit",
      padding: "0px 12px 0px 12px"
    },
    row: {
      display: "flex",
      transition: "all 50ms ease-in-out",
      outline: "none"
    },
    rowOddLines: { backgroundColor: grey[50] },
    rowHover: { backgroundColor: indigo[50] },
    rowSelected: {
      backgroundColor: theme.palette.primary.light,
      color: "white"
    },
    rowSelectedSvg: {
      "& svg": {
        stroke: "white !important"
      }
    },
    copyIcon: {
      color: `${theme.palette.primary.main}`,
      height: "22px",
      width: "22px"
    },
    rowMultiSelected: {
      backgroundColor: alpha(theme.palette.primary.main, 0.5)
    },
    checkboxCell: {
      width: 48,
      padding: "0 4px",
      borderRight: "0.3px solid #e7e7e7"
    },
    checkboxHeader: {
      width: 48,
      padding: "0 4px"
    }
  })
);

export interface MaterialTableColumn<T> {
  title?: string;
  titleTooltipIcon?: JSX.Element;
  svgHighlight?: boolean;
  field: string;
  hidden?: boolean;
  minWidth?: number;
  width?: number;
  maxWidth?: number;
  disableSort?: boolean;
  align?: TableCellProps["align"];
  type?: "numeric";
  render?: (row: T, value: any) => React.ReactNode;
  format?: (value: any) => React.ReactNode;
  sort?: (a: T, b: T) => number;
  tableCellProps?: TableCellProps & { "data-testid": string };
  defaultSort?: Order;
  currencyFormat?: UseCurrencyDisplayParams;
  currencyTable?: CurrencyTable;
}

type Order = "asc" | "desc";

export interface MultiSelectActions<T> {
  actions: {
    label: string | React.ReactNode;
    icon: React.ReactElement;
    onClick: (selectedItems: T[]) => void;
    disabled?: (selectedItems: T[]) => boolean;
    fetchStatus?: FetchingStatus | boolean;
  }[];
}

export interface MyMaterialTable2PropsVirtualizedRTK<T> {
  columns: MaterialTableColumn<T>[];
  data: T[];
  customIdName?: keyof T;
  hasFilteredData?: boolean;
  onRowClick?: (event: React.MouseEvent, row: T) => void;
  rowMenuActions?: (row: T, closeMenu: () => void) => React.ReactNode;
  disableMenuActions?: boolean;
  disableSearch?: boolean;
  menuActionsAfterEdit?: boolean;
  height?: CSSProperties["height"];
  rowHeight?: number;
  rowHeaderHeight?: number;
  defaultFieldSort?: string;
  defaultFieldSortDirection?: "asc" | "desc";
  rightActions?: React.ReactNode;
  leftActions?: React.ReactNode;
  actionComponent?: React.ReactNode;
  emptyStateProps?: EmptyStateProps;
  // Add Entity
  disabledAddElement?: boolean;
  disableRow?: (row: T) => boolean;
  orderRTK?: {
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  };
  searchRTK?: {
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
  };
  isFiltering?: boolean;
  addElement?: {
    formik: MyFormikProps<any>;
    status: FetchingStatus | boolean;
    title: string | React.ReactNode;
    dialog?: Partial<MaterialTableFormDialogProps>;
    buttonLabel: string | React.ReactNode | JSX.Element;
    buttonComponent?: (fn: (open: boolean) => void) => React.ReactNode;
    buttonProps?: MyButtonProps;
  };
  updateElement?: {
    formik: MyFormikProps<any>;
    status: FetchingStatus | boolean;
    setFormikInitialValue?: (row: T) => any;
    title: (row: T) => string | React.ReactNode;
    dialog?: Partial<MaterialTableFormDialogProps>;
    buttonLabel: (row: T) => string | React.ReactNode;
    buttonComponent?: React.ReactNode;
    buttonProps?: MyButtonProps;
    disabled?: (row: T) => boolean;
  };
  viewDetailsElement?: {
    buttonLabel: (row: T) => string | React.ReactNode;
    buttonAction?: (row: T) => void;
  };
  copyIdElementToClipboard?: {
    buttonLabel: string | React.ReactNode;
    snackBarConfirmation?: () => void;
    divider?: boolean;
  };
  materialTableActionProps?: BoxProps;
  paperProps?: { elevation: number };
  replaceEmptyValues?: boolean;
  allowBackDrop?: boolean;
  enableMultiSelect?: boolean;
  multiSelectActions?: MultiSelectActions<T>;
}

const initialMousePosition = { mouseX: null, mouseY: null };
const COLUMN_MIN_WIDTH = 200;
const ROW_HEADER_HEIGHT = 50;
const ROW_HEIGHT = 50;

export function MyMaterialTable2VirtualizedRTK<T extends { id: string }>({
  columns,
  data,
  customIdName,
  hasFilteredData,
  height,
  rowHeight,
  rowHeaderHeight,
  onRowClick,
  emptyStateProps,
  rowMenuActions,
  disableMenuActions,
  disableSearch,
  menuActionsAfterEdit,
  disableRow,
  leftActions,
  rightActions,
  addElement,
  updateElement,
  viewDetailsElement,
  materialTableActionProps,
  paperProps,
  replaceEmptyValues = true,
  orderRTK,
  searchRTK,
  isFiltering = false,
  allowBackDrop,
  disabledAddElement,
  copyIdElementToClipboard,
  enableMultiSelect,
  multiSelectActions
}: MyMaterialTable2PropsVirtualizedRTK<T>) {
  const containerRef = useRef<any>();
  const classes = useStylesMaterialTable();
  const [highlightedRow, setHighlightedRow] = useState<T>();
  const [hoverRowIndex, setHoverRowIndex] = useState<number>();
  const [openUpdateElement, setOpenUpdateElement] = useState(false);
  const [openAddElement, setOpenAddElement] = useState(false);
  const [hasInitialize, setHasInitialize] = useState(false);
  const [menuPosition, setMenuPosition] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialMousePosition);

  const [sortDirection, setSortDirection] = React.useState<Order>(
    ((orderRTK?.orderBy as string)
      ?.split(",")[1]
      .toLocaleLowerCase() as Order) || "desc"
  );

  const [currentSort, setCurrentSort] = React.useState<string>(
    (orderRTK?.orderBy as string)?.split(",")[0]
  );

  const { format: currencyFormat } = useCurrencyDisplay();

  const [list, setList] = React.useState<T[]>([]);

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const onUpdateMenuClick = () => {
    setOpenUpdateElement(true);
    setMenuPosition({
      mouseX: null,
      mouseY: null
    });
  };

  const handleMenuClose = () => {
    setMenuPosition(initialMousePosition);
  };

  const handleCopyIdToClipboard = (row: T) => {
    if (!copyIdElementToClipboard) return;

    navigator.clipboard.writeText(row.id);
    if (copyIdElementToClipboard.snackBarConfirmation) {
      copyIdElementToClipboard.snackBarConfirmation();
    }
  };

  const handleCellClassName = ({ index }: Index, isSvg = false) => {
    const classNames: ClassValue[] = [];

    classNames.push(classes.row);
    if (index % 2 === 0) {
      classNames.push(classes.rowOddLines);
    }
    if (hoverRowIndex === index) {
      classNames.push(classes.rowHover);
    }
    if (list[index]) {
      if (enableMultiSelect) {
        if (selectedRows.size > 0) {
          if (selectedRows.has(list[index][customIdName || "id"] as string)) {
            classNames.push(classes.rowMultiSelected);
          }
        } else {
          if (
            highlightedRow &&
            highlightedRow[customIdName || "id"] ===
              list[index][customIdName || "id"]
          ) {
            classNames.push(classes.rowSelected);
          }
        }
      } else if (
        highlightedRow &&
        highlightedRow[customIdName || "id"] ===
          list[index][customIdName || "id"]
      ) {
        classNames.push(classes.rowSelected);
      }
    }
    return classNames;
  };

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableCellElement>,
    row: T
  ) => {
    setHighlightedRow(row);
    if (event) {
      event.preventDefault();
      setMenuPosition({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4
      });
    }
    if (onRowClick) onRowClick(event, row);
  };

  const initList = useCallback(() => {
    setList(data);
  }, [data]);

  const initListDebounce = useDebouncedCallback(initList, 300);

  const handleSearchChange = (search: string) => {
    searchRTK?.setSearch(search);
  };

  // Change here order
  /* const handleSortChange = useCallback(
    (property: string) => {
      const isAsc = currentSort === property && sortDirection === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      const path = `${newOrder === "desc" ? "+" : "-"}${property}`;
      setCurrentSort(property);
      setSortDirection(newOrder);
      orderRTK?.setOrderBy(path);
    },
    [currentSort, sortDirection, orderRTK]
  ); */

  const handleSortChange = useCallback(
    (property: string) => {
      const isAsc = currentSort === property && sortDirection === "asc";
      const newOrder = isAsc ? "desc" : "asc";
      const path = `${property},${newOrder === "desc" ? "DESC" : "ASC"}`;
      setCurrentSort(property);
      setSortDirection(newOrder);
      orderRTK?.setOrderBy(path);
    },
    [currentSort, sortDirection, orderRTK]
  );

  const gridCellRenderer: GridCellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style
  }) => {
    if (!list) {
      return null;
    }

    // Handle checkbox column when multiselect is enabled
    if (enableMultiSelect && columnIndex === 0) {
      if (rowIndex === 0) {
        return (
          <TableCell
            component="div"
            key={key}
            style={{
              ...style,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            className={classes.checkboxHeader}
          >
            <Checkbox
              indeterminate={
                selectedRows.size > 0 && selectedRows.size < list.length
              }
              checked={selectedRows.size === list.length}
              onChange={(event) => {
                const newSelection = new Set<string>();
                if (event.target.checked) {
                  list.forEach((item) =>
                    newSelection.add(item[customIdName || "id"] as string)
                  );
                }
                setSelectedRows(newSelection);
              }}
            />
          </TableCell>
        );
      }

      const rowData = list[rowIndex - 1];
      const rowId = rowData[customIdName || "id"] as string;
      return (
        <TableCell
          component="div"
          key={key}
          style={{
            ...style,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          className={clsx(
            classes.checkboxCell,
            ...handleCellClassName({ index: rowIndex - 1 })
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <Checkbox
            checked={selectedRows.has(rowId)}
            onChange={(event) => {
              event.stopPropagation();
              const newSelection = new Set(selectedRows);
              if (event.target.checked) {
                newSelection.add(rowId);
              } else {
                newSelection.delete(rowId);
              }
              setSelectedRows(newSelection);
            }}
          />
        </TableCell>
      );
    }

    // Get column index for data columns when multiselect is enabled
    const visibleColumns = columns.filter((c) => !c.hidden);
    const actualColumnIndex = enableMultiSelect ? columnIndex - 1 : columnIndex;
    const columnData = visibleColumns[actualColumnIndex];

    const cellStyle: CSSProperties = {};
    // Headers
    if (rowIndex === 0) {
      return (
        <TableCell
          component="div"
          key={key}
          align={columnData.align || "inherit"}
          style={{
            display: "flex",
            ...style
          }}
          variant="head"
          size="small"
          sortDirection={
            currentSort === columnData.field ? sortDirection : false
          }
          className={classes.cellHeader}
          // {...column.tableCellProps}
        >
          <Box width="100%" clone>
            <TableSortLabel
              disabled={columnData.disableSort}
              active={currentSort === columnData.field}
              direction={
                currentSort === columnData.field ? sortDirection : undefined
              }
              onClick={() => handleSortChange(columnData.field)}
            >
              {columnData.title && (
                <Typography
                  variant="body2"
                  style={{ fontWeight: "bold", alignSelf: "center" }}
                >
                  {columnData.title}
                </Typography>
              )}
            </TableSortLabel>
          </Box>
        </TableCell>
      );
    }

    // row Index -1 because we create the headers with the first row
    const rowData = list[rowIndex - 1];
    const cellData = get(rowData, columnData.field);

    const currencyFormatColumn = columnData?.currencyFormat;

    const exchangeRateValue = columnData?.currencyTable?.exchangeRatePath
      ? get(rowData, columnData.currencyTable.exchangeRatePath)
      : undefined;

    const formatValue =
      currencyFormatColumn || columnData?.currencyTable?.hasCurrency
        ? currencyFormat({
            value: cellData,
            applyRate: true,
            ...(exchangeRateValue && {
              exchangeRate: exchangeRateValue
            }),
            ...currencyFormatColumn,
            ...(currencyFormatColumn?.context === CurrencyContext.COMPANY && {
              company: get(rowData, "company")
            })
          })
        : columnData.format
        ? columnData.format(cellData)
        : cellData;

    const isDisabled = disableRow && disableRow(rowData);

    // Cells
    return (
      <TableCell
        style={{ ...style, ...cellStyle }}
        key={key}
        component="div"
        className={clsx(
          classes.cell,
          ...handleCellClassName(
            { index: rowIndex - 1 },
            columnData?.svgHighlight
          )
        )}
        align={columnData.align || "inherit"}
        variant="body"
        size="small"
        onClick={(event) => handleRowClick(event, rowData)}
        onMouseEnter={() => {
          setHoverRowIndex(rowIndex - 1);
        }}
        {...columnData.tableCellProps}
      >
        {columnData.render ? (
          columnData.render(rowData, formatValue)
        ) : (
          <Box width="100%" clone>
            <Typography
              variant="body2"
              style={{ alignSelf: "center" }}
              color={isDisabled ? "textSecondary" : "initial"}
            >
              {replaceEmptyValues ? formatValue || "-" : formatValue}
            </Typography>
          </Box>
        )}
      </TableCell>
    );
  };

  const getColumnDataWidth = (params: Index): number => {
    // Handle checkbox column width
    if (enableMultiSelect && params.index === 0) {
      return 48;
    }

    const visibleColumns = columns.filter((c) => !c.hidden);
    const actualColumnIndex = enableMultiSelect
      ? params.index - 1
      : params.index;
    const column = visibleColumns[actualColumnIndex];

    // If column has explicit width, use it
    if (column?.width) {
      return column.width;
    }

    if (containerRef.current) {
      const availableWidth =
        containerRef.current.clientWidth - (enableMultiSelect ? 48 : 0);
      const minWidth = column?.minWidth || COLUMN_MIN_WIDTH;

      // If container is smaller than total minimum width, use minimum widths
      const totalMinWidth = visibleColumns.reduce(
        (acc, col) => acc + (col.minWidth || COLUMN_MIN_WIDTH),
        0
      );
      if (availableWidth < totalMinWidth) {
        return minWidth;
      }

      // Calculate flexible widths for remaining space
      const flexColumns = visibleColumns.filter((c) => !c.width).length;
      const extraWidth = Math.floor(
        (availableWidth - totalMinWidth) / flexColumns
      );

      return minWidth + (column?.width ? 0 : extraWidth);
    }

    return COLUMN_MIN_WIDTH;
  };

  const getCellHeight = (params: Index) => {
    if (params.index === 0) {
      return rowHeaderHeight || ROW_HEADER_HEIGHT;
    }
    return rowHeight || ROW_HEIGHT;
  };

  const disabledUpdateElement = useMemo(
    () =>
      updateElement?.disabled &&
      highlightedRow &&
      updateElement?.disabled(highlightedRow as any),
    [highlightedRow, updateElement]
  );

  const addElementDialog = useMemo(
    () =>
      addElement ? (
        <MaterialTableFormDialog
          type="create"
          formik={{
            ...addElement.formik,
            onSubmit: (values, helpers) => {
              setOpenAddElement(false);
              addElement.formik.onSubmit(values, helpers);
            }
          }}
          title={addElement.title}
          open={openAddElement}
          allowBackDrop={allowBackDrop}
          onClose={() => {
            setOpenAddElement(false);
          }}
          {...addElement.dialog}
        />
      ) : null,
    [addElement, allowBackDrop, openAddElement]
  );

  useEffect(() => {
    if (!hasInitialize && data.length) {
      initList();
      setHasInitialize(true);
    } else {
      initListDebounce.callback();
    }
  }, [initList, initListDebounce, hasInitialize, data]);

  useEffect(() => {
    if (
      multiSelectActions?.actions.some((action) => action.fetchStatus === true)
    ) {
      // Clear la sélection
      setSelectedRows(new Set());
    }
  }, [multiSelectActions]);

  if (
    (!data || !data.length) &&
    !hasFilteredData &&
    emptyStateProps &&
    !searchRTK?.search &&
    !isFiltering
  ) {
    return (
      <EmptyState
        onClick={() => setOpenAddElement(true)}
        {...emptyStateProps}
        dialogComponent={addElementDialog}
      />
    );
  }

  return (
    <Paper {...paperProps}>
      <MaterialTableActions {...materialTableActionProps}>
        {addElement && !disabledAddElement && (
          <MyButton
            leftIcon={<AddCircleIcon fontSize="small" />}
            color="primary"
            variant="outlined"
            style={{ height: 40 }}
            onClick={() => setOpenAddElement(true)}
            {...addElement?.buttonProps}
          >
            {addElement?.buttonLabel as string}
          </MyButton>
        )}
        {addElementDialog}
        {updateElement && highlightedRow && (
          <MaterialTableFormDialog
            type="update"
            formik={{
              ...updateElement.formik,
              onSubmit: (values, helpers) => {
                setOpenUpdateElement(false);
                updateElement.formik.onSubmit(values, helpers);
              },
              initialValues:
                (updateElement.setFormikInitialValue &&
                  updateElement.setFormikInitialValue(highlightedRow)) ||
                updateElement.formik.initialValues
            }}
            title={updateElement.title(highlightedRow)}
            open={openUpdateElement}
            onClose={() => setOpenUpdateElement(false)}
            {...updateElement.dialog}
          />
        )}
        {leftActions}
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          {!disableSearch && (
            <MaterialTableSearchRTK onChange={handleSearchChange} />
          )}
          {rightActions}
        </Box>
      </MaterialTableActions>
      <div style={{ height: height || 700, minHeight: 240 }} ref={containerRef}>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <ColumnSizer
                columnCount={
                  columns.filter((c) => !c.hidden).length +
                  (enableMultiSelect ? 1 : 0)
                }
                key="GridColumnSizer"
                width={width}
              >
                {({ adjustedWidth, registerChild }) => {
                  return (
                    <MultiGrid
                      ref={registerChild}
                      columnWidth={(params) => getColumnDataWidth(params)}
                      fixedRowCount={1}
                      cellRenderer={gridCellRenderer}
                      columnCount={
                        columns.filter((c) => !c.hidden).length +
                        (enableMultiSelect ? 1 : 0)
                      }
                      rowCount={list.length + 1}
                      rowHeight={getCellHeight}
                      width={adjustedWidth}
                      height={height}
                    />
                  );
                }}
              </ColumnSizer>
            );
          }}
        </AutoSizer>
        {(rowMenuActions || updateElement) &&
          highlightedRow &&
          !disableMenuActions && (
            <Menu
              open={menuPosition.mouseY !== null}
              onClose={handleMenuClose}
              transitionDuration={{ appear: 300, enter: 300, exit: 100 }}
              anchorReference="anchorPosition"
              anchorPosition={
                menuPosition.mouseY !== null && menuPosition.mouseX !== null
                  ? { top: menuPosition.mouseY, left: menuPosition.mouseX }
                  : undefined
              }
            >
              {enableMultiSelect && selectedRows.size > 0
                ? multiSelectActions?.actions.map((action, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        action.onClick(
                          list.filter((item) =>
                            selectedRows.has(
                              item[customIdName || "id"] as string
                            )
                          )
                        );
                        handleMenuClose();
                      }}
                      disabled={action.disabled?.(
                        list.filter((item) =>
                          selectedRows.has(item[customIdName || "id"] as string)
                        )
                      )}
                    >
                      <ListItemIcon>
                        {action.icon
                          ? React.cloneElement(action.icon, {
                              color: "primary"
                            })
                          : action.icon}
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ color: "primary" }}
                      >
                        {action.label}
                      </ListItemText>
                    </MenuItem>
                  ))
                : [
                    rowMenuActions &&
                      !menuActionsAfterEdit &&
                      rowMenuActions(highlightedRow, handleMenuClose),
                    viewDetailsElement && (
                      <MenuItem
                        onClick={() => {
                          viewDetailsElement.buttonAction &&
                            viewDetailsElement.buttonAction(highlightedRow);
                        }}
                        key="view"
                      >
                        <ListItemIcon>
                          <EyeIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                        >
                          {viewDetailsElement.buttonLabel(highlightedRow)}
                        </ListItemText>
                      </MenuItem>
                    ),
                    copyIdElementToClipboard && (
                      <MenuItem
                        onClick={() => handleCopyIdToClipboard(highlightedRow)}
                        key="copy"
                      >
                        <ListItemIcon>
                          <CopyToClipboardIcon className={classes.copyIcon} />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                        >
                          {copyIdElementToClipboard.buttonLabel}
                        </ListItemText>
                      </MenuItem>
                    ),
                    copyIdElementToClipboard?.divider && (
                      <Divider
                        style={{
                          width: "95%",
                          margin: "auto",
                          marginBottom: 4,
                          marginTop: 4
                        }}
                        key="divider"
                      />
                    ),
                    updateElement && highlightedRow && (
                      <MenuItem
                        onClick={onUpdateMenuClick}
                        disabled={disabledUpdateElement}
                        key="update"
                      >
                        <ListItemIcon>
                          <UpdateIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ color: "primary" }}
                        >
                          {updateElement.buttonLabel(highlightedRow)}
                        </ListItemText>
                      </MenuItem>
                    ),
                    rowMenuActions &&
                      menuActionsAfterEdit &&
                      rowMenuActions(highlightedRow, handleMenuClose)
                  ].filter(Boolean)}
            </Menu>
          )}
      </div>
    </Paper>
  );
}
